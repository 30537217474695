/* 
Created 200 mock positions using Mockaroo (mockaroo.com) 

tradeId (Row Number - 1 ascending incrementally)
assetId (Number between 100000-999999 - 6 digits)
ticker (Stock Symbol)
type (Naughty String - 'STK')
numShares (Number between 0-500)
price (Two-Decimal Number between 0-5000)
totalPrice (Two-Decimal Number between 0-10000)
status ("Completed" or "Processing")
tradeTime (Within 2021-01-01 and 2021-01-31)

*/

export const TRADES = [
  {
    tradeId: 1,
    assetId: 254281,
    ticker: "CIL",
    type: "STK",
    numShares: 483,
    price: 2569.36,
    totalPrice: 1897.49,
    status: "Completed",
    tradeTime: "2021-01-19 20:32:25",
  },
  {
    tradeId: 2,
    assetId: 342286,
    ticker: "VOC",
    type: "STK",
    numShares: 454,
    price: 1530.07,
    totalPrice: 1540.65,
    status: "Completed",
    tradeTime: "2021-01-11 17:54:37",
  },
  {
    tradeId: 3,
    assetId: 112231,
    ticker: "CPAAW",
    type: "STK",
    numShares: 346,
    price: 1934.42,
    totalPrice: 1267.67,
    status: "Completed",
    tradeTime: "2021-01-01 05:17:51",
  },
  {
    tradeId: 4,
    assetId: 156040,
    ticker: "FMY",
    type: "STK",
    numShares: 298,
    price: 2514.58,
    totalPrice: 2860.07,
    status: "Completed",
    tradeTime: "2021-01-25 01:46:54",
  },
  {
    tradeId: 5,
    assetId: 104502,
    ticker: "PLBC",
    type: "STK",
    numShares: 166,
    price: 605.09,
    totalPrice: 6100.0,
    status: "Completed",
    tradeTime: "2021-01-23 17:05:15",
  },
  {
    tradeId: 6,
    assetId: 420300,
    ticker: "HOPE",
    type: "STK",
    numShares: 236,
    price: 4732.76,
    totalPrice: 4787.57,
    status: "Completed",
    tradeTime: "2021-01-10 20:34:09",
  },
  {
    tradeId: 7,
    assetId: 423239,
    ticker: "WPG^I",
    type: "STK",
    numShares: 416,
    price: 1595.81,
    totalPrice: 7644.52,
    status: "Completed",
    tradeTime: "2021-01-26 14:56:10",
  },
  {
    tradeId: 8,
    assetId: 331283,
    ticker: "EDD",
    type: "STK",
    numShares: 368,
    price: 2649.65,
    totalPrice: 708.9,
    status: "Completed",
    tradeTime: "2021-01-08 08:22:46",
  },
  {
    tradeId: 9,
    assetId: 989516,
    ticker: "VAR",
    type: "STK",
    numShares: 65,
    price: 249.0,
    totalPrice: 4335.79,
    status: "Processing",
    tradeTime: "2021-01-06 19:14:17",
  },
  {
    tradeId: 10,
    assetId: 896827,
    ticker: "AHPI",
    type: "STK",
    numShares: 360,
    price: 2922.15,
    totalPrice: 3530.57,
    status: "Completed",
    tradeTime: "2021-01-03 00:48:18",
  },
  {
    tradeId: 11,
    assetId: 411982,
    ticker: "LEA",
    type: "STK",
    numShares: 376,
    price: 2116.77,
    totalPrice: 7726.38,
    status: "Completed",
    tradeTime: "2021-01-24 10:02:14",
  },
  {
    tradeId: 12,
    assetId: 972202,
    ticker: "BSMX",
    type: "STK",
    numShares: 25,
    price: 4871.38,
    totalPrice: 8693.32,
    status: "Completed",
    tradeTime: "2021-01-05 06:50:32",
  },
  {
    tradeId: 13,
    assetId: 600520,
    ticker: "FSNN",
    type: "STK",
    numShares: 300,
    price: 4812.78,
    totalPrice: 5238.18,
    status: "Completed",
    tradeTime: "2021-01-24 17:17:40",
  },
  {
    tradeId: 14,
    assetId: 328202,
    ticker: "PULM",
    type: "STK",
    numShares: 500,
    price: 1868.24,
    totalPrice: 5554.11,
    status: "Completed",
    tradeTime: "2021-01-13 12:07:56",
  },
  {
    tradeId: 15,
    assetId: 967720,
    ticker: "MFG",
    type: "STK",
    numShares: 360,
    price: 732.0,
    totalPrice: 1583.69,
    status: "Completed",
    tradeTime: "2021-01-23 00:45:35",
  },
  {
    tradeId: 16,
    assetId: 758860,
    ticker: "APH",
    type: "STK",
    numShares: 449,
    price: 4830.91,
    totalPrice: 9454.34,
    status: "Completed",
    tradeTime: "2021-01-16 21:05:03",
  },
  {
    tradeId: 17,
    assetId: 957820,
    ticker: "GES",
    type: "STK",
    numShares: 310,
    price: 1638.51,
    totalPrice: 6946.7,
    status: "Completed",
    tradeTime: "2021-01-27 10:52:09",
  },
  {
    tradeId: 18,
    assetId: 245793,
    ticker: "RF",
    type: "STK",
    numShares: 143,
    price: 494.06,
    totalPrice: 1357.17,
    status: "Completed",
    tradeTime: "2021-01-20 21:40:12",
  },
  {
    tradeId: 19,
    assetId: 193515,
    ticker: "AEZS",
    type: "STK",
    numShares: 94,
    price: 716.77,
    totalPrice: 8245.58,
    status: "Completed",
    tradeTime: "2021-01-22 02:11:00",
  },
  {
    tradeId: 20,
    assetId: 954034,
    ticker: "NCV",
    type: "STK",
    numShares: 284,
    price: 2039.21,
    totalPrice: 7642.76,
    status: "Completed",
    tradeTime: "2021-01-13 16:06:03",
  },
  {
    tradeId: 21,
    assetId: 632583,
    ticker: "BKEPP",
    type: "STK",
    numShares: 363,
    price: 2106.29,
    totalPrice: 2143.06,
    status: "Completed",
    tradeTime: "2021-01-09 02:38:33",
  },
  {
    tradeId: 22,
    assetId: 746760,
    ticker: "DFS^B",
    type: "STK",
    numShares: 413,
    price: 2255.97,
    totalPrice: 3466.77,
    status: "Completed",
    tradeTime: "2021-01-15 20:24:24",
  },
  {
    tradeId: 23,
    assetId: 798192,
    ticker: "HMST",
    type: "STK",
    numShares: 230,
    price: 4989.19,
    totalPrice: 3000.98,
    status: "Completed",
    tradeTime: "2021-01-06 11:42:35",
  },
  {
    tradeId: 24,
    assetId: 113572,
    ticker: "VALU",
    type: "STK",
    numShares: 281,
    price: 3374.57,
    totalPrice: 1234.0,
    status: "Completed",
    tradeTime: "2021-01-02 05:56:56",
  },
  {
    tradeId: 25,
    assetId: 104518,
    ticker: "HQH",
    type: "STK",
    numShares: 228,
    price: 2229.13,
    totalPrice: 7286.14,
    status: "Completed",
    tradeTime: "2021-01-16 04:12:08",
  },
  {
    tradeId: 26,
    assetId: 874714,
    ticker: "WFT",
    type: "STK",
    numShares: 159,
    price: 3496.12,
    totalPrice: 5381.23,
    status: "Completed",
    tradeTime: "2021-01-23 05:27:34",
  },
  {
    tradeId: 27,
    assetId: 431162,
    ticker: "AUBN",
    type: "STK",
    numShares: 197,
    price: 4869.1,
    totalPrice: 8948.49,
    status: "Completed",
    tradeTime: "2021-01-12 07:57:11",
  },
  {
    tradeId: 28,
    assetId: 321850,
    ticker: "DXLG",
    type: "STK",
    numShares: 87,
    price: 3270.87,
    totalPrice: 7697.5,
    status: "Completed",
    tradeTime: "2021-01-17 13:47:06",
  },
  {
    tradeId: 29,
    assetId: 561295,
    ticker: "USA",
    type: "STK",
    numShares: 230,
    price: 871.55,
    totalPrice: 2147.4,
    status: "Completed",
    tradeTime: "2021-01-07 19:13:53",
  },
  {
    tradeId: 30,
    assetId: 538772,
    ticker: "EOS",
    type: "STK",
    numShares: 171,
    price: 127.11,
    totalPrice: 805.48,
    status: "Processing",
    tradeTime: "2021-01-11 00:26:50",
  },
  {
    tradeId: 31,
    assetId: 919980,
    ticker: "AKO.B",
    type: "STK",
    numShares: 67,
    price: 4183.38,
    totalPrice: 5110.72,
    status: "Completed",
    tradeTime: "2021-01-28 08:02:44",
  },
  {
    tradeId: 32,
    assetId: 852691,
    ticker: "EQCO",
    type: "STK",
    numShares: 130,
    price: 1050.07,
    totalPrice: 7743.62,
    status: "Completed",
    tradeTime: "2021-01-26 11:31:24",
  },
  {
    tradeId: 33,
    assetId: 567636,
    ticker: "CETV",
    type: "STK",
    numShares: 89,
    price: 964.63,
    totalPrice: 4474.37,
    status: "Completed",
    tradeTime: "2021-01-25 07:46:36",
  },
  {
    tradeId: 34,
    assetId: 616175,
    ticker: "RFT",
    type: "STK",
    numShares: 247,
    price: 208.17,
    totalPrice: 6796.04,
    status: "Processing",
    tradeTime: "2021-01-23 18:23:21",
  },
  {
    tradeId: 35,
    assetId: 349017,
    ticker: "BUD",
    type: "STK",
    numShares: 286,
    price: 4498.99,
    totalPrice: 2732.35,
    status: "Completed",
    tradeTime: "2021-01-27 17:11:55",
  },
  {
    tradeId: 36,
    assetId: 193503,
    ticker: "XBIO",
    type: "STK",
    numShares: 104,
    price: 3944.78,
    totalPrice: 9467.13,
    status: "Completed",
    tradeTime: "2021-01-13 10:55:55",
  },
  {
    tradeId: 37,
    assetId: 276624,
    ticker: "ZAIS",
    type: "STK",
    numShares: 241,
    price: 2911.13,
    totalPrice: 9562.64,
    status: "Completed",
    tradeTime: "2021-01-11 11:40:16",
  },
  {
    tradeId: 38,
    assetId: 388727,
    ticker: "SBNA",
    type: "STK",
    numShares: 1,
    price: 2497.74,
    totalPrice: 2252.08,
    status: "Completed",
    tradeTime: "2021-01-18 21:26:15",
  },
  {
    tradeId: 39,
    assetId: 707476,
    ticker: "ENBL",
    type: "STK",
    numShares: 335,
    price: 1531.31,
    totalPrice: 9815.01,
    status: "Completed",
    tradeTime: "2021-01-19 23:37:33",
  },
  {
    tradeId: 40,
    assetId: 148323,
    ticker: "BST",
    type: "STK",
    numShares: 301,
    price: 4605.42,
    totalPrice: 8488.47,
    status: "Completed",
    tradeTime: "2021-01-03 12:58:27",
  },
  {
    tradeId: 41,
    assetId: 205177,
    ticker: "ENSG",
    type: "STK",
    numShares: 231,
    price: 4882.1,
    totalPrice: 2694.54,
    status: "Completed",
    tradeTime: "2021-01-10 11:04:20",
  },
  {
    tradeId: 42,
    assetId: 683589,
    ticker: "SAN^I",
    type: "STK",
    numShares: 100,
    price: 4600.05,
    totalPrice: 7926.72,
    status: "Completed",
    tradeTime: "2021-01-22 19:44:53",
  },
  {
    tradeId: 43,
    assetId: 112687,
    ticker: "HOTR",
    type: "STK",
    numShares: 391,
    price: 683.6,
    totalPrice: 3167.28,
    status: "Completed",
    tradeTime: "2021-01-17 01:09:38",
  },
  {
    tradeId: 44,
    assetId: 890057,
    ticker: "ECOM          ",
    type: "STK",
    numShares: 56,
    price: 3002.12,
    totalPrice: 9656.38,
    status: "Completed",
    tradeTime: "2021-01-15 10:51:19",
  },
  {
    tradeId: 45,
    assetId: 431406,
    ticker: "FSAM",
    type: "STK",
    numShares: 61,
    price: 4904.49,
    totalPrice: 8165.37,
    status: "Completed",
    tradeTime: "2021-01-19 07:41:00",
  },
  {
    tradeId: 46,
    assetId: 552201,
    ticker: "OIBR.C",
    type: "STK",
    numShares: 200,
    price: 1394.55,
    totalPrice: 7696.51,
    status: "Completed",
    tradeTime: "2021-01-11 14:34:36",
  },
  {
    tradeId: 47,
    assetId: 160340,
    ticker: "XEL",
    type: "STK",
    numShares: 423,
    price: 4212.07,
    totalPrice: 8107.58,
    status: "Completed",
    tradeTime: "2021-01-04 04:31:13",
  },
  {
    tradeId: 48,
    assetId: 324307,
    ticker: "LOB",
    type: "STK",
    numShares: 92,
    price: 1306.44,
    totalPrice: 5517.66,
    status: "Completed",
    tradeTime: "2021-01-03 08:55:22",
  },
  {
    tradeId: 49,
    assetId: 912180,
    ticker: "APA",
    type: "STK",
    numShares: 265,
    price: 4583.59,
    totalPrice: 4251.12,
    status: "Completed",
    tradeTime: "2021-01-14 06:09:13",
  },
  {
    tradeId: 50,
    assetId: 484188,
    ticker: "ALE",
    type: "STK",
    numShares: 51,
    price: 2705.59,
    totalPrice: 4426.4,
    status: "Completed",
    tradeTime: "2021-01-04 20:39:38",
  },
  {
    tradeId: 51,
    assetId: 555639,
    ticker: "CLPR",
    type: "STK",
    numShares: 310,
    price: 1447.8,
    totalPrice: 8905.21,
    status: "Completed",
    tradeTime: "2021-01-14 23:29:20",
  },
  {
    tradeId: 52,
    assetId: 329005,
    ticker: "SCHL",
    type: "STK",
    numShares: 156,
    price: 3006.48,
    totalPrice: 4438.26,
    status: "Completed",
    tradeTime: "2021-01-22 18:02:30",
  },
  {
    tradeId: 53,
    assetId: 934043,
    ticker: "FRC^F",
    type: "STK",
    numShares: 151,
    price: 4628.99,
    totalPrice: 8921.69,
    status: "Completed",
    tradeTime: "2021-01-06 14:53:30",
  },
  {
    tradeId: 54,
    assetId: 767621,
    ticker: "AOI",
    type: "STK",
    numShares: 163,
    price: 3890.72,
    totalPrice: 5523.38,
    status: "Completed",
    tradeTime: "2021-01-11 06:29:47",
  },
  {
    tradeId: 55,
    assetId: 324943,
    ticker: "GBL",
    type: "STK",
    numShares: 324,
    price: 4567.09,
    totalPrice: 4223.85,
    status: "Completed",
    tradeTime: "2021-01-16 14:55:33",
  },
  {
    tradeId: 56,
    assetId: 166066,
    ticker: "HAL",
    type: "STK",
    numShares: 24,
    price: 1251.61,
    totalPrice: 2993.94,
    status: "Completed",
    tradeTime: "2021-01-28 03:45:35",
  },
  {
    tradeId: 57,
    assetId: 491825,
    ticker: "GSBD",
    type: "STK",
    numShares: 237,
    price: 1618.53,
    totalPrice: 9272.05,
    status: "Completed",
    tradeTime: "2021-01-02 21:51:35",
  },
  {
    tradeId: 58,
    assetId: 594713,
    ticker: "SNOAW",
    type: "STK",
    numShares: 70,
    price: 4396.56,
    totalPrice: 4711.64,
    status: "Completed",
    tradeTime: "2021-01-20 22:50:01",
  },
  {
    tradeId: 59,
    assetId: 419121,
    ticker: "PNI",
    type: "STK",
    numShares: 107,
    price: 1511.43,
    totalPrice: 554.35,
    status: "Completed",
    tradeTime: "2021-01-22 14:33:49",
  },
  {
    tradeId: 60,
    assetId: 765132,
    ticker: "DLHC",
    type: "STK",
    numShares: 43,
    price: 1801.8,
    totalPrice: 6834.85,
    status: "Completed",
    tradeTime: "2021-01-09 17:24:27",
  },
  {
    tradeId: 61,
    assetId: 217433,
    ticker: "PRA",
    type: "STK",
    numShares: 365,
    price: 3163.64,
    totalPrice: 9220.88,
    status: "Completed",
    tradeTime: "2021-01-13 09:05:04",
  },
  {
    tradeId: 62,
    assetId: 644851,
    ticker: "TAHO",
    type: "STK",
    numShares: 20,
    price: 235.12,
    totalPrice: 464.06,
    status: "Processing",
    tradeTime: "2021-01-03 09:11:07",
  },
  {
    tradeId: 63,
    assetId: 400666,
    ticker: "WFC.WS",
    type: "STK",
    numShares: 269,
    price: 4371.34,
    totalPrice: 1948.27,
    status: "Completed",
    tradeTime: "2021-01-15 00:45:53",
  },
  {
    tradeId: 64,
    assetId: 174620,
    ticker: "UFPI",
    type: "STK",
    numShares: 130,
    price: 1127.84,
    totalPrice: 7273.2,
    status: "Completed",
    tradeTime: "2021-01-04 06:51:21",
  },
  {
    tradeId: 65,
    assetId: 267582,
    ticker: "GNRX",
    type: "STK",
    numShares: 308,
    price: 3498.74,
    totalPrice: 7532.92,
    status: "Completed",
    tradeTime: "2021-01-21 19:47:27",
  },
  {
    tradeId: 66,
    assetId: 436024,
    ticker: "BVN",
    type: "STK",
    numShares: 454,
    price: 1133.31,
    totalPrice: 176.31,
    status: "Completed",
    tradeTime: "2021-01-12 01:21:49",
  },
  {
    tradeId: 67,
    assetId: 781538,
    ticker: "HOMB",
    type: "STK",
    numShares: 19,
    price: 637.17,
    totalPrice: 3111.22,
    status: "Completed",
    tradeTime: "2021-01-19 06:18:18",
  },
  {
    tradeId: 68,
    assetId: 355885,
    ticker: "NXST",
    type: "STK",
    numShares: 56,
    price: 2142.12,
    totalPrice: 1306.4,
    status: "Completed",
    tradeTime: "2021-01-01 10:50:18",
  },
  {
    tradeId: 69,
    assetId: 982500,
    ticker: "FARM",
    type: "STK",
    numShares: 75,
    price: 943.27,
    totalPrice: 3389.91,
    status: "Completed",
    tradeTime: "2021-01-27 05:49:45",
  },
  {
    tradeId: 70,
    assetId: 966401,
    ticker: "OCLR",
    type: "STK",
    numShares: 418,
    price: 2461.32,
    totalPrice: 1693.29,
    status: "Completed",
    tradeTime: "2021-01-06 03:08:52",
  },
  {
    tradeId: 71,
    assetId: 724966,
    ticker: "USB^A",
    type: "STK",
    numShares: 200,
    price: 2472.83,
    totalPrice: 2165.35,
    status: "Completed",
    tradeTime: "2021-01-24 12:59:59",
  },
  {
    tradeId: 72,
    assetId: 651035,
    ticker: "IPDN",
    type: "STK",
    numShares: 174,
    price: 2247.49,
    totalPrice: 4987.66,
    status: "Completed",
    tradeTime: "2021-01-10 08:06:57",
  },
  {
    tradeId: 73,
    assetId: 469949,
    ticker: "RZA",
    type: "STK",
    numShares: 41,
    price: 227.79,
    totalPrice: 6121.11,
    status: "Processing",
    tradeTime: "2021-01-27 00:03:39",
  },
  {
    tradeId: 74,
    assetId: 923107,
    ticker: "FEM",
    type: "STK",
    numShares: 341,
    price: 3914.31,
    totalPrice: 3396.78,
    status: "Completed",
    tradeTime: "2021-01-24 08:00:37",
  },
  {
    tradeId: 75,
    assetId: 890422,
    ticker: "JOBS",
    type: "STK",
    numShares: 194,
    price: 1833.22,
    totalPrice: 9407.64,
    status: "Completed",
    tradeTime: "2021-01-07 18:56:35",
  },
  {
    tradeId: 76,
    assetId: 143712,
    ticker: "CST",
    type: "STK",
    numShares: 316,
    price: 3831.9,
    totalPrice: 4655.92,
    status: "Completed",
    tradeTime: "2021-01-22 03:31:04",
  },
  {
    tradeId: 77,
    assetId: 840256,
    ticker: "TWO",
    type: "STK",
    numShares: 116,
    price: 1311.05,
    totalPrice: 7694.54,
    status: "Completed",
    tradeTime: "2021-01-02 22:01:50",
  },
  {
    tradeId: 78,
    assetId: 286813,
    ticker: "GARS",
    type: "STK",
    numShares: 122,
    price: 2536.03,
    totalPrice: 4511.37,
    status: "Completed",
    tradeTime: "2021-01-20 15:14:37",
  },
  {
    tradeId: 79,
    assetId: 547303,
    ticker: "PAVM",
    type: "STK",
    numShares: 112,
    price: 4890.34,
    totalPrice: 705.63,
    status: "Completed",
    tradeTime: "2021-01-27 19:21:33",
  },
  {
    tradeId: 80,
    assetId: 456239,
    ticker: "WSFSL",
    type: "STK",
    numShares: 143,
    price: 2868.51,
    totalPrice: 1766.81,
    status: "Completed",
    tradeTime: "2021-01-06 14:47:31",
  },
  {
    tradeId: 81,
    assetId: 145577,
    ticker: "DNP",
    type: "STK",
    numShares: 384,
    price: 1846.03,
    totalPrice: 7101.66,
    status: "Completed",
    tradeTime: "2021-01-18 16:48:12",
  },
  {
    tradeId: 82,
    assetId: 753774,
    ticker: "VMI",
    type: "STK",
    numShares: 445,
    price: 1180.21,
    totalPrice: 3244.45,
    status: "Completed",
    tradeTime: "2021-01-30 14:21:41",
  },
  {
    tradeId: 83,
    assetId: 761171,
    ticker: "NWHM",
    type: "STK",
    numShares: 343,
    price: 3025.27,
    totalPrice: 4332.8,
    status: "Completed",
    tradeTime: "2021-01-23 20:52:38",
  },
  {
    tradeId: 84,
    assetId: 697646,
    ticker: "BSE",
    type: "STK",
    numShares: 250,
    price: 4541.37,
    totalPrice: 4710.93,
    status: "Completed",
    tradeTime: "2021-01-08 10:51:34",
  },
  {
    tradeId: 85,
    assetId: 881303,
    ticker: "NXST",
    type: "STK",
    numShares: 156,
    price: 321.96,
    totalPrice: 3417.54,
    status: "Processing",
    tradeTime: "2021-01-19 16:53:31",
  },
  {
    tradeId: 86,
    assetId: 122762,
    ticker: "HEP",
    type: "STK",
    numShares: 190,
    price: 2668.32,
    totalPrice: 5211.84,
    status: "Completed",
    tradeTime: "2021-01-03 12:06:09",
  },
  {
    tradeId: 87,
    assetId: 530832,
    ticker: "WTFC",
    type: "STK",
    numShares: 158,
    price: 835.63,
    totalPrice: 7038.16,
    status: "Completed",
    tradeTime: "2021-01-28 04:07:50",
  },
  {
    tradeId: 88,
    assetId: 464076,
    ticker: "HRMNU",
    type: "STK",
    numShares: 416,
    price: 1273.64,
    totalPrice: 5169.33,
    status: "Completed",
    tradeTime: "2021-01-03 06:22:55",
  },
  {
    tradeId: 89,
    assetId: 206168,
    ticker: "RBS",
    type: "STK",
    numShares: 120,
    price: 3738.37,
    totalPrice: 1436.97,
    status: "Completed",
    tradeTime: "2021-01-21 16:41:05",
  },
  {
    tradeId: 90,
    assetId: 972369,
    ticker: "SSL",
    type: "STK",
    numShares: 25,
    price: 1367.46,
    totalPrice: 9052.82,
    status: "Completed",
    tradeTime: "2021-01-08 11:57:15",
  },
  {
    tradeId: 91,
    assetId: 418352,
    ticker: "YEXT",
    type: "STK",
    numShares: 53,
    price: 4676.7,
    totalPrice: 7000.39,
    status: "Completed",
    tradeTime: "2021-01-04 01:50:30",
  },
  {
    tradeId: 92,
    assetId: 660153,
    ticker: "GRAM",
    type: "STK",
    numShares: 285,
    price: 1287.86,
    totalPrice: 9574.51,
    status: "Completed",
    tradeTime: "2021-01-29 09:45:00",
  },
  {
    tradeId: 93,
    assetId: 334866,
    ticker: "MNP",
    type: "STK",
    numShares: 360,
    price: 3159.74,
    totalPrice: 8147.45,
    status: "Completed",
    tradeTime: "2021-01-10 20:00:23",
  },
  {
    tradeId: 94,
    assetId: 869295,
    ticker: "CRTO",
    type: "STK",
    numShares: 422,
    price: 4958.09,
    totalPrice: 9600.84,
    status: "Completed",
    tradeTime: "2021-01-12 09:48:25",
  },
  {
    tradeId: 95,
    assetId: 201811,
    ticker: "PDT",
    type: "STK",
    numShares: 267,
    price: 1395.77,
    totalPrice: 1848.11,
    status: "Completed",
    tradeTime: "2021-01-27 18:37:04",
  },
  {
    tradeId: 96,
    assetId: 662256,
    ticker: "CHEK",
    type: "STK",
    numShares: 188,
    price: 3771.6,
    totalPrice: 712.91,
    status: "Completed",
    tradeTime: "2021-01-13 23:23:09",
  },
  {
    tradeId: 97,
    assetId: 795727,
    ticker: "H",
    type: "STK",
    numShares: 228,
    price: 4351.78,
    totalPrice: 2457.64,
    status: "Completed",
    tradeTime: "2021-01-24 00:12:47",
  },
  {
    tradeId: 98,
    assetId: 210305,
    ticker: "NGHCP",
    type: "STK",
    numShares: 321,
    price: 908.45,
    totalPrice: 7075.71,
    status: "Completed",
    tradeTime: "2021-01-05 09:49:07",
  },
  {
    tradeId: 99,
    assetId: 908936,
    ticker: "TDE",
    type: "STK",
    numShares: 460,
    price: 4216.46,
    totalPrice: 8196.08,
    status: "Completed",
    tradeTime: "2021-01-21 03:53:23",
  },
  {
    tradeId: 100,
    assetId: 405006,
    ticker: "KNDI",
    type: "STK",
    numShares: 176,
    price: 4359.65,
    totalPrice: 445.66,
    status: "Completed",
    tradeTime: "2021-01-02 06:53:02",
  },
  {
    tradeId: 101,
    assetId: 187946,
    ticker: "RRR",
    type: "STK",
    numShares: 449,
    price: 3375.49,
    totalPrice: 9340.57,
    status: "Completed",
    tradeTime: "2021-01-16 10:43:02",
  },
  {
    tradeId: 102,
    assetId: 536673,
    ticker: "NXEOU",
    type: "STK",
    numShares: 371,
    price: 1505.01,
    totalPrice: 1420.8,
    status: "Completed",
    tradeTime: "2021-01-17 08:12:00",
  },
  {
    tradeId: 103,
    assetId: 863746,
    ticker: "QTNT",
    type: "STK",
    numShares: 467,
    price: 3058.98,
    totalPrice: 3733.24,
    status: "Completed",
    tradeTime: "2021-01-10 10:52:04",
  },
  {
    tradeId: 104,
    assetId: 842425,
    ticker: "ON",
    type: "STK",
    numShares: 491,
    price: 2832.65,
    totalPrice: 4812.22,
    status: "Completed",
    tradeTime: "2021-01-06 18:19:24",
  },
  {
    tradeId: 105,
    assetId: 723580,
    ticker: "SPRT",
    type: "STK",
    numShares: 96,
    price: 1476.5,
    totalPrice: 9218.04,
    status: "Completed",
    tradeTime: "2021-01-26 05:37:41",
  },
  {
    tradeId: 106,
    assetId: 763252,
    ticker: "COVS",
    type: "STK",
    numShares: 139,
    price: 2091.09,
    totalPrice: 681.59,
    status: "Completed",
    tradeTime: "2021-01-02 22:38:14",
  },
  {
    tradeId: 107,
    assetId: 170242,
    ticker: "GUT^A",
    type: "STK",
    numShares: 477,
    price: 2699.74,
    totalPrice: 1822.89,
    status: "Completed",
    tradeTime: "2021-01-01 13:33:38",
  },
  {
    tradeId: 108,
    assetId: 856146,
    ticker: "ENFC",
    type: "STK",
    numShares: 312,
    price: 2256.11,
    totalPrice: 5707.36,
    status: "Completed",
    tradeTime: "2021-01-03 23:34:14",
  },
  {
    tradeId: 109,
    assetId: 846601,
    ticker: "ETY",
    type: "STK",
    numShares: 488,
    price: 3502.42,
    totalPrice: 8392.55,
    status: "Completed",
    tradeTime: "2021-01-14 10:29:15",
  },
  {
    tradeId: 110,
    assetId: 532125,
    ticker: "CVNA",
    type: "STK",
    numShares: 413,
    price: 832.08,
    totalPrice: 9520.98,
    status: "Completed",
    tradeTime: "2021-01-28 21:03:54",
  },
  {
    tradeId: 111,
    assetId: 770343,
    ticker: "STOR",
    type: "STK",
    numShares: 347,
    price: 2776.31,
    totalPrice: 315.68,
    status: "Completed",
    tradeTime: "2021-01-12 21:59:13",
  },
  {
    tradeId: 112,
    assetId: 239850,
    ticker: "NEE^I",
    type: "STK",
    numShares: 401,
    price: 3901.75,
    totalPrice: 2741.42,
    status: "Completed",
    tradeTime: "2021-01-06 00:40:48",
  },
  {
    tradeId: 113,
    assetId: 942361,
    ticker: "WIFI",
    type: "STK",
    numShares: 50,
    price: 2620.77,
    totalPrice: 4054.32,
    status: "Completed",
    tradeTime: "2021-01-07 08:00:26",
  },
  {
    tradeId: 114,
    assetId: 210744,
    ticker: "ARCB",
    type: "STK",
    numShares: 207,
    price: 1049.15,
    totalPrice: 9428.04,
    status: "Completed",
    tradeTime: "2021-01-09 16:12:28",
  },
  {
    tradeId: 115,
    assetId: 384454,
    ticker: "UPS",
    type: "STK",
    numShares: 227,
    price: 814.63,
    totalPrice: 2918.04,
    status: "Completed",
    tradeTime: "2021-01-22 10:53:28",
  },
  {
    tradeId: 116,
    assetId: 598866,
    ticker: "ORIG",
    type: "STK",
    numShares: 157,
    price: 186.34,
    totalPrice: 4417.78,
    status: "Processing",
    tradeTime: "2021-01-10 14:03:05",
  },
  {
    tradeId: 117,
    assetId: 343119,
    ticker: "CMS^B",
    type: "STK",
    numShares: 377,
    price: 562.74,
    totalPrice: 3759.04,
    status: "Completed",
    tradeTime: "2021-01-05 06:46:24",
  },
  {
    tradeId: 118,
    assetId: 764295,
    ticker: "SHLD",
    type: "STK",
    numShares: 159,
    price: 897.67,
    totalPrice: 6200.44,
    status: "Completed",
    tradeTime: "2021-01-27 03:04:39",
  },
  {
    tradeId: 119,
    assetId: 261483,
    ticker: "CRI",
    type: "STK",
    numShares: 117,
    price: 1108.66,
    totalPrice: 6667.18,
    status: "Completed",
    tradeTime: "2021-01-07 13:24:36",
  },
  {
    tradeId: 120,
    assetId: 687765,
    ticker: "GCI",
    type: "STK",
    numShares: 280,
    price: 3293.22,
    totalPrice: 3250.04,
    status: "Completed",
    tradeTime: "2021-01-28 21:41:04",
  },
  {
    tradeId: 121,
    assetId: 585459,
    ticker: "EWZS",
    type: "STK",
    numShares: 28,
    price: 3716.93,
    totalPrice: 6088.25,
    status: "Completed",
    tradeTime: "2021-01-29 03:03:45",
  },
  {
    tradeId: 122,
    assetId: 826533,
    ticker: "LECO",
    type: "STK",
    numShares: 86,
    price: 4062.38,
    totalPrice: 3126.88,
    status: "Completed",
    tradeTime: "2021-01-16 18:19:17",
  },
  {
    tradeId: 123,
    assetId: 739940,
    ticker: "ERIE",
    type: "STK",
    numShares: 209,
    price: 4837.29,
    totalPrice: 2204.17,
    status: "Completed",
    tradeTime: "2021-01-05 14:11:21",
  },
  {
    tradeId: 124,
    assetId: 911266,
    ticker: "DTLA^",
    type: "STK",
    numShares: 22,
    price: 2827.26,
    totalPrice: 4456.66,
    status: "Completed",
    tradeTime: "2021-01-11 15:19:37",
  },
  {
    tradeId: 125,
    assetId: 556145,
    ticker: "IFEU",
    type: "STK",
    numShares: 150,
    price: 4248.5,
    totalPrice: 2019.19,
    status: "Completed",
    tradeTime: "2021-01-24 23:54:43",
  },
  {
    tradeId: 126,
    assetId: 938579,
    ticker: "SHLX",
    type: "STK",
    numShares: 103,
    price: 1816.37,
    totalPrice: 1869.99,
    status: "Completed",
    tradeTime: "2021-01-28 21:31:10",
  },
  {
    tradeId: 127,
    assetId: 650657,
    ticker: "PGEM",
    type: "STK",
    numShares: 121,
    price: 4572.07,
    totalPrice: 7954.47,
    status: "Completed",
    tradeTime: "2021-01-02 12:55:48",
  },
  {
    tradeId: 128,
    assetId: 372516,
    ticker: "WGO",
    type: "STK",
    numShares: 136,
    price: 4775.31,
    totalPrice: 374.29,
    status: "Completed",
    tradeTime: "2021-01-06 07:43:45",
  },
  {
    tradeId: 129,
    assetId: 236446,
    ticker: "SAM",
    type: "STK",
    numShares: 7,
    price: 401.81,
    totalPrice: 4849.98,
    status: "Completed",
    tradeTime: "2021-01-05 08:51:03",
  },
  {
    tradeId: 130,
    assetId: 883995,
    ticker: "UBNK",
    type: "STK",
    numShares: 1,
    price: 837.05,
    totalPrice: 1016.9,
    status: "Completed",
    tradeTime: "2021-01-15 19:22:50",
  },
  {
    tradeId: 131,
    assetId: 959557,
    ticker: "FVC",
    type: "STK",
    numShares: 6,
    price: 2629.29,
    totalPrice: 1817.82,
    status: "Completed",
    tradeTime: "2021-01-30 02:40:01",
  },
  {
    tradeId: 132,
    assetId: 422680,
    ticker: "VIPS",
    type: "STK",
    numShares: 126,
    price: 4787.36,
    totalPrice: 9983.33,
    status: "Completed",
    tradeTime: "2021-01-25 16:11:49",
  },
  {
    tradeId: 133,
    assetId: 463694,
    ticker: "EPD",
    type: "STK",
    numShares: 63,
    price: 2996.99,
    totalPrice: 138.95,
    status: "Completed",
    tradeTime: "2021-01-28 03:51:31",
  },
  {
    tradeId: 134,
    assetId: 500928,
    ticker: "FRC^E",
    type: "STK",
    numShares: 192,
    price: 1567.31,
    totalPrice: 4030.66,
    status: "Completed",
    tradeTime: "2021-01-03 09:24:46",
  },
  {
    tradeId: 135,
    assetId: 337035,
    ticker: "FSV",
    type: "STK",
    numShares: 182,
    price: 870.64,
    totalPrice: 7119.87,
    status: "Completed",
    tradeTime: "2021-01-05 12:46:56",
  },
  {
    tradeId: 136,
    assetId: 474676,
    ticker: "NCB",
    type: "STK",
    numShares: 134,
    price: 977.95,
    totalPrice: 1111.77,
    status: "Completed",
    tradeTime: "2021-01-14 22:20:37",
  },
  {
    tradeId: 137,
    assetId: 721322,
    ticker: "ECOM          ",
    type: "STK",
    numShares: 10,
    price: 1784.02,
    totalPrice: 5190.57,
    status: "Completed",
    tradeTime: "2021-01-30 13:26:27",
  },
  {
    tradeId: 138,
    assetId: 152310,
    ticker: "GLA",
    type: "STK",
    numShares: 416,
    price: 2296.34,
    totalPrice: 2937.89,
    status: "Completed",
    tradeTime: "2021-01-23 06:32:23",
  },
  {
    tradeId: 139,
    assetId: 616992,
    ticker: "DAN",
    type: "STK",
    numShares: 220,
    price: 1670.15,
    totalPrice: 3160.81,
    status: "Completed",
    tradeTime: "2021-01-02 17:29:51",
  },
  {
    tradeId: 140,
    assetId: 123458,
    ticker: "JOE",
    type: "STK",
    numShares: 31,
    price: 4419.29,
    totalPrice: 6600.03,
    status: "Completed",
    tradeTime: "2021-01-02 11:32:18",
  },
  {
    tradeId: 141,
    assetId: 793527,
    ticker: "ACSF",
    type: "STK",
    numShares: 385,
    price: 2151.27,
    totalPrice: 4124.05,
    status: "Completed",
    tradeTime: "2021-01-16 09:22:18",
  },
  {
    tradeId: 142,
    assetId: 550945,
    ticker: "PRH",
    type: "STK",
    numShares: 437,
    price: 59.63,
    totalPrice: 9620.53,
    status: "Processing",
    tradeTime: "2021-01-08 12:33:42",
  },
  {
    tradeId: 143,
    assetId: 593878,
    ticker: "AGO^B",
    type: "STK",
    numShares: 132,
    price: 1047.61,
    totalPrice: 1733.54,
    status: "Completed",
    tradeTime: "2021-01-07 02:12:33",
  },
  {
    tradeId: 144,
    assetId: 834119,
    ticker: "ITCI",
    type: "STK",
    numShares: 341,
    price: 1624.4,
    totalPrice: 6364.36,
    status: "Completed",
    tradeTime: "2021-01-16 21:04:22",
  },
  {
    tradeId: 145,
    assetId: 392668,
    ticker: "BRKL",
    type: "STK",
    numShares: 67,
    price: 2511.54,
    totalPrice: 4218.58,
    status: "Completed",
    tradeTime: "2021-01-18 04:43:27",
  },
  {
    tradeId: 146,
    assetId: 281648,
    ticker: "EYESW",
    type: "STK",
    numShares: 32,
    price: 3175.29,
    totalPrice: 5017.35,
    status: "Completed",
    tradeTime: "2021-01-22 06:27:40",
  },
  {
    tradeId: 147,
    assetId: 586287,
    ticker: "AIZ",
    type: "STK",
    numShares: 303,
    price: 2282.39,
    totalPrice: 7863.87,
    status: "Completed",
    tradeTime: "2021-01-20 09:41:49",
  },
  {
    tradeId: 148,
    assetId: 301718,
    ticker: "GLUU",
    type: "STK",
    numShares: 421,
    price: 3732.61,
    totalPrice: 9737.36,
    status: "Completed",
    tradeTime: "2021-01-01 11:24:26",
  },
  {
    tradeId: 149,
    assetId: 834303,
    ticker: "BNS",
    type: "STK",
    numShares: 424,
    price: 3688.71,
    totalPrice: 5523.17,
    status: "Completed",
    tradeTime: "2021-01-25 20:51:54",
  },
  {
    tradeId: 150,
    assetId: 105680,
    ticker: "EVOL",
    type: "STK",
    numShares: 402,
    price: 4747.72,
    totalPrice: 3511.18,
    status: "Completed",
    tradeTime: "2021-01-17 02:26:34",
  },
  {
    tradeId: 151,
    assetId: 365402,
    ticker: "EACQU",
    type: "STK",
    numShares: 348,
    price: 200.45,
    totalPrice: 3236.99,
    status: "Processing",
    tradeTime: "2021-01-19 00:09:41",
  },
  {
    tradeId: 152,
    assetId: 576284,
    ticker: "GPACW",
    type: "STK",
    numShares: 243,
    price: 4412.19,
    totalPrice: 6764.9,
    status: "Completed",
    tradeTime: "2021-01-25 23:29:34",
  },
  {
    tradeId: 153,
    assetId: 434195,
    ticker: "DCIX",
    type: "STK",
    numShares: 484,
    price: 2001.43,
    totalPrice: 5120.37,
    status: "Completed",
    tradeTime: "2021-01-29 09:21:27",
  },
  {
    tradeId: 154,
    assetId: 895979,
    ticker: "PSC",
    type: "STK",
    numShares: 389,
    price: 762.62,
    totalPrice: 3776.15,
    status: "Completed",
    tradeTime: "2021-01-26 08:51:31",
  },
  {
    tradeId: 155,
    assetId: 146162,
    ticker: "FTRPR",
    type: "STK",
    numShares: 324,
    price: 1599.49,
    totalPrice: 9697.96,
    status: "Completed",
    tradeTime: "2021-01-04 21:21:07",
  },
  {
    tradeId: 156,
    assetId: 463370,
    ticker: "HLT",
    type: "STK",
    numShares: 129,
    price: 4189.27,
    totalPrice: 5121.03,
    status: "Completed",
    tradeTime: "2021-01-11 01:34:43",
  },
  {
    tradeId: 157,
    assetId: 389727,
    ticker: "RILYZ",
    type: "STK",
    numShares: 439,
    price: 1669.98,
    totalPrice: 4153.58,
    status: "Completed",
    tradeTime: "2021-01-04 17:05:50",
  },
  {
    tradeId: 158,
    assetId: 624652,
    ticker: "FTNT",
    type: "STK",
    numShares: 201,
    price: 3231.66,
    totalPrice: 9977.22,
    status: "Completed",
    tradeTime: "2021-01-14 15:53:40",
  },
  {
    tradeId: 159,
    assetId: 384992,
    ticker: "MTSI",
    type: "STK",
    numShares: 173,
    price: 1904.82,
    totalPrice: 1829.3,
    status: "Completed",
    tradeTime: "2021-01-28 10:42:29",
  },
  {
    tradeId: 160,
    assetId: 129923,
    ticker: "PAH",
    type: "STK",
    numShares: 233,
    price: 1502.12,
    totalPrice: 5402.51,
    status: "Completed",
    tradeTime: "2021-01-06 11:40:58",
  },
  {
    tradeId: 161,
    assetId: 558932,
    ticker: "OXM",
    type: "STK",
    numShares: 150,
    price: 4040.12,
    totalPrice: 3230.25,
    status: "Completed",
    tradeTime: "2021-01-21 23:03:49",
  },
  {
    tradeId: 162,
    assetId: 339964,
    ticker: "TDE",
    type: "STK",
    numShares: 338,
    price: 1143.68,
    totalPrice: 2506.94,
    status: "Completed",
    tradeTime: "2021-01-08 22:44:11",
  },
  {
    tradeId: 163,
    assetId: 806307,
    ticker: "PATI",
    type: "STK",
    numShares: 377,
    price: 4728.28,
    totalPrice: 1709.3,
    status: "Completed",
    tradeTime: "2021-01-17 11:52:12",
  },
  {
    tradeId: 164,
    assetId: 322313,
    ticker: "OAKS",
    type: "STK",
    numShares: 92,
    price: 3974.97,
    totalPrice: 8066.2,
    status: "Completed",
    tradeTime: "2021-01-11 11:05:36",
  },
  {
    tradeId: 165,
    assetId: 586518,
    ticker: "ROST",
    type: "STK",
    numShares: 361,
    price: 1210.3,
    totalPrice: 921.38,
    status: "Completed",
    tradeTime: "2021-01-29 20:16:55",
  },
  {
    tradeId: 166,
    assetId: 954908,
    ticker: "IRS",
    type: "STK",
    numShares: 55,
    price: 1654.98,
    totalPrice: 9370.5,
    status: "Completed",
    tradeTime: "2021-01-09 12:53:41",
  },
  {
    tradeId: 167,
    assetId: 639726,
    ticker: "JBN",
    type: "STK",
    numShares: 40,
    price: 3591.97,
    totalPrice: 8749.45,
    status: "Completed",
    tradeTime: "2021-01-18 03:30:25",
  },
  {
    tradeId: 168,
    assetId: 437719,
    ticker: "SAN^A",
    type: "STK",
    numShares: 129,
    price: 778.97,
    totalPrice: 9822.19,
    status: "Completed",
    tradeTime: "2021-01-21 11:13:49",
  },
  {
    tradeId: 169,
    assetId: 943505,
    ticker: "STT",
    type: "STK",
    numShares: 388,
    price: 3522.64,
    totalPrice: 6030.68,
    status: "Completed",
    tradeTime: "2021-01-11 11:24:40",
  },
  {
    tradeId: 170,
    assetId: 117579,
    ticker: "OXLCN",
    type: "STK",
    numShares: 165,
    price: 4936.2,
    totalPrice: 2515.77,
    status: "Completed",
    tradeTime: "2021-01-21 19:02:21",
  },
  {
    tradeId: 171,
    assetId: 754352,
    ticker: "HTBI",
    type: "STK",
    numShares: 35,
    price: 3083.78,
    totalPrice: 4220.51,
    status: "Completed",
    tradeTime: "2021-01-06 12:09:05",
  },
  {
    tradeId: 172,
    assetId: 278536,
    ticker: "FRSX",
    type: "STK",
    numShares: 180,
    price: 3406.41,
    totalPrice: 6342.55,
    status: "Completed",
    tradeTime: "2021-01-11 03:21:54",
  },
  {
    tradeId: 173,
    assetId: 558266,
    ticker: "GPM",
    type: "STK",
    numShares: 207,
    price: 69.96,
    totalPrice: 6610.74,
    status: "Processing",
    tradeTime: "2021-01-30 09:29:32",
  },
  {
    tradeId: 174,
    assetId: 991257,
    ticker: "CCK",
    type: "STK",
    numShares: 6,
    price: 4434.87,
    totalPrice: 2378.55,
    status: "Completed",
    tradeTime: "2021-01-22 19:27:49",
  },
  {
    tradeId: 175,
    assetId: 519487,
    ticker: "MTB",
    type: "STK",
    numShares: 242,
    price: 2430.64,
    totalPrice: 8964.45,
    status: "Completed",
    tradeTime: "2021-01-17 21:49:51",
  },
  {
    tradeId: 176,
    assetId: 754317,
    ticker: "NAKD",
    type: "STK",
    numShares: 48,
    price: 4186.34,
    totalPrice: 3261.62,
    status: "Completed",
    tradeTime: "2021-01-07 15:11:35",
  },
  {
    tradeId: 177,
    assetId: 644701,
    ticker: "ELS",
    type: "STK",
    numShares: 446,
    price: 2511.01,
    totalPrice: 1043.12,
    status: "Completed",
    tradeTime: "2021-01-17 11:55:40",
  },
  {
    tradeId: 178,
    assetId: 292722,
    ticker: "BFO",
    type: "STK",
    numShares: 370,
    price: 1554.27,
    totalPrice: 1746.61,
    status: "Completed",
    tradeTime: "2021-01-29 07:50:39",
  },
  {
    tradeId: 179,
    assetId: 933389,
    ticker: "ALL^D",
    type: "STK",
    numShares: 37,
    price: 3616.65,
    totalPrice: 2106.89,
    status: "Completed",
    tradeTime: "2021-01-29 20:48:27",
  },
  {
    tradeId: 180,
    assetId: 710094,
    ticker: "BP",
    type: "STK",
    numShares: 490,
    price: 4717.5,
    totalPrice: 2606.14,
    status: "Completed",
    tradeTime: "2021-01-22 14:21:55",
  },
  {
    tradeId: 181,
    assetId: 268896,
    ticker: "KBWB",
    type: "STK",
    numShares: 414,
    price: 3846.25,
    totalPrice: 6487.54,
    status: "Completed",
    tradeTime: "2021-01-04 22:01:51",
  },
  {
    tradeId: 182,
    assetId: 205399,
    ticker: "FULT",
    type: "STK",
    numShares: 252,
    price: 3525.35,
    totalPrice: 1347.1,
    status: "Completed",
    tradeTime: "2021-01-03 23:49:56",
  },
  {
    tradeId: 183,
    assetId: 344595,
    ticker: "PFLT",
    type: "STK",
    numShares: 405,
    price: 1602.17,
    totalPrice: 145.73,
    status: "Completed",
    tradeTime: "2021-01-22 22:02:02",
  },
  {
    tradeId: 184,
    assetId: 694281,
    ticker: "ICHR",
    type: "STK",
    numShares: 179,
    price: 2257.1,
    totalPrice: 1415.01,
    status: "Completed",
    tradeTime: "2021-01-08 02:03:46",
  },
  {
    tradeId: 185,
    assetId: 857421,
    ticker: "CBL^D",
    type: "STK",
    numShares: 71,
    price: 3038.18,
    totalPrice: 1681.35,
    status: "Completed",
    tradeTime: "2021-01-08 20:57:46",
  },
  {
    tradeId: 186,
    assetId: 585253,
    ticker: "GYRO",
    type: "STK",
    numShares: 40,
    price: 349.68,
    totalPrice: 7115.14,
    status: "Processing",
    tradeTime: "2021-01-06 11:36:23",
  },
  {
    tradeId: 187,
    assetId: 350357,
    ticker: "MSLI",
    type: "STK",
    numShares: 239,
    price: 1310.05,
    totalPrice: 3566.81,
    status: "Completed",
    tradeTime: "2021-01-04 02:25:42",
  },
  {
    tradeId: 188,
    assetId: 777930,
    ticker: "SIVBO",
    type: "STK",
    numShares: 418,
    price: 1151.72,
    totalPrice: 7961.04,
    status: "Completed",
    tradeTime: "2021-01-21 19:20:49",
  },
  {
    tradeId: 189,
    assetId: 762500,
    ticker: "AXARW",
    type: "STK",
    numShares: 53,
    price: 4276.33,
    totalPrice: 6329.14,
    status: "Completed",
    tradeTime: "2021-01-08 05:22:52",
  },
  {
    tradeId: 190,
    assetId: 506669,
    ticker: "FLIC",
    type: "STK",
    numShares: 315,
    price: 4829.11,
    totalPrice: 6582.48,
    status: "Completed",
    tradeTime: "2021-01-09 03:52:51",
  },
  {
    tradeId: 191,
    assetId: 743934,
    ticker: "AIB",
    type: "STK",
    numShares: 133,
    price: 3524.72,
    totalPrice: 4429.36,
    status: "Completed",
    tradeTime: "2021-01-02 00:49:06",
  },
  {
    tradeId: 192,
    assetId: 721139,
    ticker: "FLAG",
    type: "STK",
    numShares: 17,
    price: 3804.26,
    totalPrice: 6383.93,
    status: "Completed",
    tradeTime: "2021-01-09 12:47:50",
  },
  {
    tradeId: 193,
    assetId: 946670,
    ticker: "LOR",
    type: "STK",
    numShares: 439,
    price: 314.12,
    totalPrice: 1417.46,
    status: "Processing",
    tradeTime: "2021-01-28 20:15:15",
  },
  {
    tradeId: 194,
    assetId: 790122,
    ticker: "NVLN",
    type: "STK",
    numShares: 298,
    price: 3197.96,
    totalPrice: 2604.06,
    status: "Completed",
    tradeTime: "2021-01-03 19:32:02",
  },
  {
    tradeId: 195,
    assetId: 660113,
    ticker: "REGN",
    type: "STK",
    numShares: 276,
    price: 1981.75,
    totalPrice: 8595.09,
    status: "Completed",
    tradeTime: "2021-01-02 17:54:01",
  },
  {
    tradeId: 196,
    assetId: 297123,
    ticker: "KN",
    type: "STK",
    numShares: 101,
    price: 2894.58,
    totalPrice: 5559.85,
    status: "Completed",
    tradeTime: "2021-01-07 18:16:58",
  },
  {
    tradeId: 197,
    assetId: 615733,
    ticker: "GLT",
    type: "STK",
    numShares: 265,
    price: 4406.19,
    totalPrice: 1808.83,
    status: "Completed",
    tradeTime: "2021-01-12 02:42:33",
  },
  {
    tradeId: 198,
    assetId: 199258,
    ticker: "LPNT",
    type: "STK",
    numShares: 456,
    price: 1115.63,
    totalPrice: 6255.76,
    status: "Completed",
    tradeTime: "2021-01-05 20:39:40",
  },
  {
    tradeId: 199,
    assetId: 519137,
    ticker: "LANC",
    type: "STK",
    numShares: 106,
    price: 2879.9,
    totalPrice: 2116.69,
    status: "Completed",
    tradeTime: "2021-01-12 10:21:17",
  },
  {
    tradeId: 200,
    assetId: 760242,
    ticker: "KW",
    type: "STK",
    numShares: 344,
    price: 432.6,
    totalPrice: 9007.53,
    status: "Completed",
    tradeTime: "2021-01-06 21:09:44",
  },
];
