import React from "react";
import { Content } from "../components/SharedStyles";

const NotFound = () => (
  <Content>
    <h1>404</h1>
    <p>Page Not Found</p>
  </Content>
);

export default NotFound;
